import React from 'react';

const AboutUs = () => {
    return (
        <div className="content">
            <h1>About Us</h1>
            <p>This is the about us page .</p>
        </div>
    );
};

export default AboutUs;
